











































































































@import "../styles/variable";

.logo {
  height: 60px;
  overflow: hidden;
  span {
    display: block;
    height: 52px;
    overflow: hidden;
    border-bottom: 2px solid $secondary;
    padding: 10px;
    img {
      height: 100%;
    }
  }
}

/deep/ .vsm--item {
  transition: opacity 0.2s;
}

/deep/ .menu-icon {
  font-size: 1.5em;
  background-color: $dark !important;
  padding-bottom: 15px;
  svg {
    top: 3px;
    left: 3px;
  }
}

.menu,
/deep/ .vsm--toggle-btn {
  background-color: $dark;
}
.logo-wrapper {
  padding: 3px !important;
}
