











































@import "styles/variable";

.menu-collapsed {
    transition: all 0.3s ease;
    padding-left: 50px;
}
.menu-not-collapsed {
    transition: all 0.3s;
    padding-left: $menu-width;
}
body {
    min-width: 320px;
}

body,
html,
.container {
    height: 100% !important;
}
.content-wrapper {
    padding-top: 15px;
    padding-bottom: 60px !important;
}
