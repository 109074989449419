































































@import "../../styles/variable";

.menu-collapsed {
  transition: all 0.3s ease;
  left: 38px;
  width: calc(100% - 38px);
  padding-left: 25px;
}
.menu-not-collapsed {
  transition: all 0.3s;
  left: 38px;
  width: calc(100% - 38px);
  padding-left: 25px;

  padding-left: calc(#{$menu-width} - 25px);
}

.top-line {
  border-bottom: 4px solid $secondary;
}

.top-line {
  border-bottom: 4px solid $secondary;
}

h1 {
  color: $secondary;
  font-size: $h3-font-size;
  margin-bottom: $headings-margin-bottom / 2;
  font-weight: bold;
}

.navbar-expand .navbar-nav .navbar-text {
  padding: 0;
}

ol.breadcrumb {
  border: 0;
  padding: 0;
  margin-bottom: 0;
  background: transparent;
  font-size: $font-size-xs;
  text-transform: none;
  a {
    color: $gray-500;
  }
}
